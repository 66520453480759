"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var GenderOptions = [{
  text: 'Female',
  key: 'female'
}, {
  text: 'Male',
  key: 'male'
}, {
  text: 'Unknown',
  key: 'unknown'
}, {
  text: 'Other',
  key: 'other'
}];
var _default = GenderOptions;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(GenderOptions, "GenderOptions", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/gender-options.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/gender-options.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();