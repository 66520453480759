"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _merge = _interopRequireDefault(require("lodash/merge"));
var _pulseFacilitySelection = require("@audacious/pulse-facility-selection");
var _endpoints = _interopRequireDefault(require("../configs/endpoints"));
var _createCheckInService = _interopRequireDefault(require("./create-check-in-service"));
var _createCheckOutService = _interopRequireDefault(require("./create-check-out-service"));
var _runSearchQuery = _interopRequireDefault(require("./run-search-query"));
var _retrieveEpraPreferences = _interopRequireDefault(require("./epra-preferences/retrieveEpraPreferences"));
var _getCheckedInPatients = _interopRequireDefault(require("./get-checked-in-patients"));
var _getCheckedOutPatients = _interopRequireDefault(require("./get-checked-out-patients"));
var _updateCheckInPatient = _interopRequireDefault(require("./update-check-in-patient"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var DEFAULT_CONFIG = {
  endpoints: _endpoints.default
};
var _default = function _default(config) {
  var configuration = (0, _merge.default)(DEFAULT_CONFIG, config); // eslint-disable-line

  return [(0, _createCheckInService.default)(configuration), (0, _createCheckOutService.default)(configuration), (0, _runSearchQuery.default)(configuration), (0, _retrieveEpraPreferences.default)(configuration), (0, _getCheckedInPatients.default)(configuration), (0, _getCheckedOutPatients.default)(configuration), (0, _updateCheckInPatient.default)(configuration)].concat((0, _toConsumableArray2.default)(_pulseFacilitySelection.services));
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(DEFAULT_CONFIG, "DEFAULT_CONFIG", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/index.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();