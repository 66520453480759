"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function beforeRequest(actionContext) {
  actionContext.dispatch('EDIT_CHECK_IN_PATIENT_START');
}
function onSuccess(actionContext, _ref) {
  var response = _ref.response;
  var _response$data = response.data,
    succeeded = _response$data.successes,
    failed = _response$data.failures;
  if (!(0, _isEmpty.default)(failed)) {
    actionContext.dispatch('EDIT_CHECK_IN_PATIENT_FAILED', {
      failed: failed[0]
    });
  } else {
    actionContext.dispatch('EDIT_CHECK_IN_PATIENT_SUCCESS', {
      succeeded: succeeded,
      failed: failed
    });
  }
}
function onFailed(actionContext, _ref2) {
  var error = _ref2.error;
  actionContext.dispatch('EDIT_CHECK_IN_PATIENT_FAILED', {
    failed: error,
    succeeded: []
  });
}
var _default = function _default(config) {
  return {
    serviceName: 'updateCheckIn',
    authenticate: true,
    config: {
      method: 'PUT',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: (0, _get.default)(config, 'endpoints.emergencyCensus.checkIn.edit')
    },
    beforeRequest: beforeRequest,
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(beforeRequest, "beforeRequest", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/update-check-in-patient.js");
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/update-check-in-patient.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/update-check-in-patient.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/update-check-in-patient.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();