"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zipCodePattern = exports.socialPattern = exports.phonePatternJustNumbers = exports.phonePattern = exports.namePattern = exports.countryCodePattern = exports.basicPattern = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var namePattern = exports.namePattern = /^(?!\s)(?![\s\S]*\s$)^[A-Za-z0-9\s\-']*$/;
var zipCodePattern = exports.zipCodePattern = /^([0-9]{5})(-[0-9]{4})?$/;
var countryCodePattern = exports.countryCodePattern = /^\+\d{1,4}$/;
var phonePattern = exports.phonePattern = /^\d{3}-\d{3}-\d{4}$/;
var socialPattern = exports.socialPattern = /(^\d{3}-\d{2}-\d{4}$)|(^\d{3}\d{2}\d{4}$)|(^\d{4}$)/;
var phonePatternJustNumbers = exports.phonePatternJustNumbers = /^\d{10}$/;
var basicPattern = exports.basicPattern = /^[^&~^\\]*$/;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(namePattern, "namePattern", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(zipCodePattern, "zipCodePattern", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(countryCodePattern, "countryCodePattern", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(phonePattern, "phonePattern", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(socialPattern, "socialPattern", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(phonePatternJustNumbers, "phonePatternJustNumbers", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/reg-ex-patterns.js");
  reactHotLoader.register(basicPattern, "basicPattern", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/reg-ex-patterns.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();