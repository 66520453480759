"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _map = _interopRequireDefault(require("lodash/map"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _toLower = _interopRequireDefault(require("lodash/toLower"));
var _genderOptions = _interopRequireDefault(require("./gender-options"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function validateGender(value) {
  var genderValues = (0, _map.default)(_genderOptions.default, function (gender) {
    return (0, _toLower.default)(gender.text);
  });
  if (!value) {
    return null;
  }
  if (!(0, _includes.default)(genderValues, (0, _toLower.default)(value))) {
    return 'Enter a valid gender';
  }
  return null;
}
var _default = validateGender;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(validateGender, "validateGender", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/validate-gender.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/validate-gender.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();