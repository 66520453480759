"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.array.iterator");
require("core-js/modules/es.function.name");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.promise");
require("core-js/modules/es.string.iterator");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.processFiles = processFiles;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _papaparse = _interopRequireDefault(require("papaparse"));
var _map = _interopRequireDefault(require("lodash/map"));
var _set = _interopRequireDefault(require("lodash/set"));
var _trim = _interopRequireDefault(require("lodash/trim"));
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _reduce = _interopRequireDefault(require("lodash/reduce"));
var _endsWith = _interopRequireDefault(require("lodash/endsWith"));
var _toLower = _interopRequireDefault(require("lodash/toLower"));
var _bluebird = _interopRequireDefault(require("bluebird"));
var _lodash = require("lodash");
var _uuid = require("uuid");
var _columnDefinitions = require("../csv/column-definitions");
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
function buildColumnLookup(columnHeaders) {
  var columnBreakdown = [];
  var quickDefinitionClone = _objectSpread({}, _columnDefinitions.columnDefinitions);
  (0, _forEach.default)(columnHeaders, function (columnHeader) {
    var columnName = (0, _toLower.default)(columnHeader);
    var columnDefinition = quickDefinitionClone[columnName];
    if ((0, _isNil.default)(columnDefinition)) {
      throw new Error("Invalid Column \"".concat(columnName, "\""));
    }
    columnBreakdown.push(columnDefinition);
    quickDefinitionClone[columnName] = null;
  });
  (0, _forEach.default)(quickDefinitionClone, function (columnDefinition) {
    if ((0, _isNil.default)(columnDefinition) || !columnDefinition.required) {
      return;
    }
    throw new Error("Missing required column \"".concat(columnDefinition.label, "\""));
  });
  return columnBreakdown;
}
function convertToCheckIn(line, columnBreakdown) {
  var patient = {};
  var original = {};
  var issues = [];
  var lineMessages = [];
  if (line.length < columnBreakdown.length) {
    lineMessages.push('Too few columns');
  }
  if (line.length > columnBreakdown.length) {
    lineMessages.push('Too many columns');
  }
  if (lineMessages.length > 0) {
    issues.push({
      path: '',
      label: 'General',
      messages: lineMessages
    });
  }
  var hasValues = false;
  (0, _forEach.default)(columnBreakdown, function (columnDefinition, index) {
    (0, _set.default)(original, columnDefinition.path, line[index]);
    var lineItem = (0, _trim.default)(line[index]);
    if (!(0, _lodash.isEmpty)(lineItem)) {
      // We just we just want to know if the original value even existed.
      // Some values will default to a value and we don't want to mistake
      // that for a real value if there was none originally.
      hasValues = true;
    } else {
      lineItem = null;
    }
    var _columnDefinition$pro = columnDefinition.process(lineItem),
      value = _columnDefinition$pro.value,
      messages = _columnDefinition$pro.messages;
    if (!(0, _lodash.isEmpty)(messages)) {
      issues.push({
        label: columnDefinition.label,
        path: columnDefinition.path,
        messages: messages
      });
    }
    (0, _set.default)(patient, columnDefinition.path, value);
  });
  (0, _set.default)(patient, 'tempId', (0, _uuid.v4)());
  if (!hasValues) {
    return null;
  }
  return {
    issues: issues,
    patient: patient,
    original: original
  };
}
function processFile(file) {
  if (!(0, _endsWith.default)(file.name, '.csv')) {
    throw new Error('Invalid file type');
  }
  return _bluebird.default.fromCallback(function (callback) {
    _papaparse.default.parse(file, {
      complete: function complete(result) {
        var errors = result.errors,
          data = result.data;
        var executeError = null;
        if (!(0, _isNil.default)(errors) && errors.length > 0) {
          (0, _forEach.default)(errors, function (error) {
            return console.error(error.message);
          });
          executeError = new Error('Selected File(s) are invalid');
        }
        callback(executeError, data);
      }
    });
  }).then(function (lines) {
    var checkIns = [];
    var columnLookup = buildColumnLookup(lines[0]);
    for (var counter = 1; counter < lines.length; counter += 1) {
      var line = lines[counter];
      var checkIn = convertToCheckIn(line, columnLookup);
      if (!(0, _isNil.default)(checkIn)) {
        checkIns.push(checkIn);
      }
    }
    return checkIns;
  });
}
function processFiles(context, files) {
  context.dispatch('CHECK_IN_BULK_UPLOAD_START');
  return _bluebird.default.delay(100).then(function () {
    var proms = (0, _map.default)(files, function (file) {
      return processFile(file);
    });
    return _bluebird.default.all(proms);
  }).then(function (fileCheckIns) {
    return (0, _reduce.default)(fileCheckIns, function (acc, checkIns) {
      return acc.concat(checkIns);
    }, []);
  }).then(function (checkIns) {
    context.dispatch('CHECK_IN_BULK_UPLOAD_FINISH', checkIns);
  }).catch(function (error) {
    context.dispatch('CHECK_IN_BULK_UPLOAD_FAILED', error);
  });
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(buildColumnLookup, "buildColumnLookup", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/bulk-upload-actions.js");
  reactHotLoader.register(convertToCheckIn, "convertToCheckIn", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/bulk-upload-actions.js");
  reactHotLoader.register(processFile, "processFile", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/bulk-upload-actions.js");
  reactHotLoader.register(processFiles, "processFiles", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/bulk-upload-actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();