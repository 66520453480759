"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var searchDataPropType = _propTypes.default.shape({
  query: {
    firstName: _propTypes.default.string,
    lastName: _propTypes.default.string
  },
  results: _propTypes.default.arrayOf(_propTypes.default.shape({
    formatted: _propTypes.default.shape({
      name: _propTypes.default.string,
      address: _propTypes.default.string,
      dob: _propTypes.default.string,
      phone: _propTypes.default.string,
      gender: _propTypes.default.string
    })
  })),
  running: _propTypes.default.bool,
  error: _propTypes.default.string
});
var _default = searchDataPropType;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(searchDataPropType, "searchDataPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/components/workflows/check-out/search-data-prop-type.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/components/workflows/check-out/search-data-prop-type.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();