"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.function.name");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _usaStates = _interopRequireDefault(require("usa-states"));
var _map = _interopRequireDefault(require("lodash/map"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var statesAndTerritories = new _usaStates.default.UsaStates({
  includeTerritories: true
});
var StatesOptions = (0, _map.default)(statesAndTerritories.states, function (state) {
  return {
    key: state.abbreviation,
    text: "".concat(state.abbreviation, " - ").concat(state.name),
    selectedText: state.abbreviation
  };
});
var _default = StatesOptions;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(statesAndTerritories, "statesAndTerritories", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/usa-states-options.js");
  reactHotLoader.register(StatesOptions, "StatesOptions", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/usa-states-options.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/usa-states-options.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();