"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildPatientsCSV = buildPatientsCSV;
exports.downloadCSV = downloadCSV;
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _get = _interopRequireDefault(require("lodash/get"));
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _columnDefinitions = require("./column-definitions");
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function buildPatientCSVString(patient, groupId, columnPostfix) {
  var csvString = '';
  var patientData = !(0, _isNil.default)(groupId) ? patient[groupId] : patient;
  (0, _forEach.default)(_columnDefinitions.definitionOrder, function (definitionId, index) {
    var definition = _columnDefinitions.columnDefinitions[definitionId];
    csvString += (0, _get.default)(patientData, definition.path, '');
    if (index < _columnDefinitions.definitionOrder.length - 1) {
      csvString += ',';
    }
  });
  if (!(0, _isNil.default)(columnPostfix)) {
    csvString += ',';
    csvString += columnPostfix(patient, groupId);
  }
  return csvString;
}
function buildPatientsCSV(patients, groupId, headerPrefix, columnPostfix) {
  var csvString = '';
  (0, _forEach.default)(_columnDefinitions.definitionOrder, function (definitionId, index) {
    var definition = _columnDefinitions.columnDefinitions[definitionId];
    csvString += definition.label;
    if (index < _columnDefinitions.definitionOrder.length - 1) {
      csvString += ',';
    }
  });
  if (!(0, _isNil.default)(headerPrefix)) {
    csvString += ',';
    csvString += headerPrefix();
  }
  csvString += '\n';
  (0, _forEach.default)(patients, function (patient, patientIndex) {
    csvString += buildPatientCSVString(patient, groupId, columnPostfix);
    if (patientIndex < patients.length - 1) {
      csvString += '\n';
    }
  });
  return csvString;
}
function downloadCSV(csvString, fileName) {
  var link = document.createElement('a');
  link.href = "data:text/csv;charset=UTF-8,".concat(encodeURIComponent(csvString));
  link.download = "".concat(fileName, ".csv");
  link.click();
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(buildPatientCSVString, "buildPatientCSVString", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/utilities.js");
  reactHotLoader.register(buildPatientsCSV, "buildPatientsCSV", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/utilities.js");
  reactHotLoader.register(downloadCSV, "downloadCSV", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/utilities.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();