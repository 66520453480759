"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkinThenGoHome = checkinThenGoHome;
exports.goBackToHomeWithDrawerOpen = goBackToHomeWithDrawerOpen;
exports.goBackToPrevious = goBackToPrevious;
exports.removeInvalidCheckIns = removeInvalidCheckIns;
exports.resetCheckIns = resetCheckIns;
exports.reviewCheckIns = reviewCheckIns;
exports.startCheckIn = startCheckIn;
exports.stopDisplayNewPatientNotification = stopDisplayNewPatientNotification;
exports.stopDisplayUpdateErrorNotification = stopDisplayUpdateErrorNotification;
exports.updateReviewCheckIns = updateReviewCheckIns;
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _reduce = _interopRequireDefault(require("lodash/reduce"));
var _join = _interopRequireDefault(require("lodash/join"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _date = _interopRequireDefault(require("@audacious/components/lib/formatters/date"));
var _utilities = require("../csv/utilities");
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function startCheckIn(actionContext) {
  actionContext.dispatch('CHECK_IN_START');
}
function reviewCheckIns(actionContext, checkIns) {
  actionContext.dispatch('CHECK_IN_REVIEW', checkIns);
}
function updateReviewCheckIns(actionContext, checkInPatient) {
  actionContext.dispatch('UPDATE_CHECK_IN_REVIEW', checkInPatient);
}
function resetCheckIns(actionContext) {
  actionContext.dispatch('RESET_CHECK_IN');
}
function goBackToPrevious(actionContext) {
  actionContext.dispatch('CHECK_IN_PREVIOUS');
}
function goBackToHomeWithDrawerOpen(actionContext) {
  actionContext.dispatch('CHECK_IN_DRAWER_OPEN');
}
function checkinThenGoHome(actionContext, _ref) {
  var tenantId = _ref.tenantId,
    facilityId = _ref.facilityId,
    patients = _ref.patients,
    history = _ref.history;
  var createCheckIns = actionContext.service.createCheckIns;

  // return Promise.fromCallback(() => {
  createCheckIns({
    options: {
      tenantId: tenantId,
      facilityId: facilityId
    },
    data: {
      patients: patients
    }
  }, function goToHome() {
    history.push('/intermediate');
  });
  // });
}
function stopDisplayNewPatientNotification(actionContext) {
  actionContext.dispatch('STOP_DISPLAY_NEW_PATIENT_NOTIFICATION');
  actionContext.dispatch('STOP_DISPLAY_UPDATE_PATIENT_NOTIFICATION');
}
function stopDisplayUpdateErrorNotification(actionContext) {
  actionContext.dispatch('STOP_DISPLAY_UPDATE_ERROR_NOTIFICATION');
}
function removeInvalidCheckIns(actionContext, _ref2) {
  var checkIns = _ref2.checkIns,
    downloadErrorLog = _ref2.downloadErrorLog;
  var validCheckIns = [];
  var invalidCheckIns = [];
  var maxNumberOfErrors = 0;
  (0, _forEach.default)(checkIns, function (checkIn) {
    if ((0, _isEmpty.default)(checkIn.issues)) {
      validCheckIns.push(checkIn);
    } else {
      invalidCheckIns.push(checkIn);
      if (checkIn.issues.length > maxNumberOfErrors) {
        maxNumberOfErrors = checkIn.issues.length;
      }
    }
  });
  actionContext.dispatch('SET_CHECK_INS', validCheckIns);
  if (!downloadErrorLog) {
    return;
  }
  var sessionStore = actionContext.getStore('Session');
  var user = sessionStore.getUser();
  var facility = sessionStore.getFacility();
  var facilityName = facility ? facility.getName() : '';
  var locationName = facility ? facility.getLocationName() : '';
  var firstName = user.getFirstName();
  var lastName = user.getLastName();
  var userName = "".concat(firstName, " ").concat(lastName);
  var csvString = '';
  csvString += "User Name,".concat(userName, "\n");
  csvString += "Facility,".concat(facilityName, "\n");
  csvString += "Location,".concat(locationName, "\n");
  csvString += "Date,".concat((0, _date.default)(new Date().toString(), {
    outFormat: 'MM/DD/YYYY h:mm a'
  }), "\n");
  csvString += '\n';
  var headerPrefix = function headerPrefix() {
    var errorHeaders = '';
    for (var headerIndex = 1; headerIndex <= maxNumberOfErrors; headerIndex += 1) {
      errorHeaders += "Error ".concat(headerIndex);
      if (headerIndex < maxNumberOfErrors) {
        errorHeaders += ',';
      }
    }
    return errorHeaders;
  };
  var rowPrefix = function rowPrefix(patient) {
    var issues = patient.issues;
    return (0, _reduce.default)(issues, function (acc, issue, issueIndex) {
      var label = issue.label,
        messages = issue.messages;

      // eslint-disable-next-line no-param-reassign
      acc += "".concat(label, ": ").concat((0, _join.default)(messages, '; '));
      if (issueIndex < issues.length - 1) {
        // eslint-disable-next-line no-param-reassign
        acc += ',';
      }
      return acc;
    }, '');
  };
  csvString += (0, _utilities.buildPatientsCSV)(invalidCheckIns, 'original', headerPrefix, rowPrefix);
  (0, _utilities.downloadCSV)(csvString, 'imported-checkin-error-log');
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(startCheckIn, "startCheckIn", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(reviewCheckIns, "reviewCheckIns", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(updateReviewCheckIns, "updateReviewCheckIns", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(resetCheckIns, "resetCheckIns", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(goBackToPrevious, "goBackToPrevious", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(goBackToHomeWithDrawerOpen, "goBackToHomeWithDrawerOpen", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(checkinThenGoHome, "checkinThenGoHome", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(stopDisplayNewPatientNotification, "stopDisplayNewPatientNotification", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(stopDisplayUpdateErrorNotification, "stopDisplayUpdateErrorNotification", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
  reactHotLoader.register(removeInvalidCheckIns, "removeInvalidCheckIns", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-in-actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();