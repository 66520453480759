"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
var _map = _interopRequireDefault(require("lodash/map"));
var _date = _interopRequireDefault(require("@audacious/components/lib/formatters/date"));
var _exportPatientsCsv = _interopRequireDefault(require("../../common/util/export-patients-csv"));
var _printPatients = _interopRequireDefault(require("../../common/util/print-patients"));
var _formatPatient = _interopRequireDefault(require("../../common/util/format-patient"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function formatPatients(patients) {
  var formattedPatients = (0, _map.default)(patients, function (patient) {
    // eslint-disable-next-line no-param-reassign
    return {
      id: patient.mrn,
      patient: patient,
      formatted: (0, _formatPatient.default)(patient)
    };
  });
  return formattedPatients;
}
function beforeRequest(actionContext, _ref) {
  var params = _ref.params,
    options = _ref.options;
  var exportType = (0, _get.default)(options, 'exportType');
  if (exportType) {
    actionContext.dispatch('CHECK_OUT_PATIENT_EXPORT_START');
  } else {
    actionContext.dispatch('CHECK_OUT_SEARCH_START', {
      firstName: params.firstName,
      lastName: params.lastName,
      page: params.page,
      pageSize: params.pageSize
    });
  }
}
function onSuccess(actionContext, _ref2) {
  var response = _ref2.response,
    options = _ref2.options,
    _ref2$params = _ref2.params,
    params = _ref2$params === void 0 ? {} : _ref2$params;
  var exportType = (0, _get.default)(options, 'exportType');
  if (exportType) {
    var patients = (0, _get.default)(response.data, 'checkins', []);
    var userName = (0, _get.default)(options, 'userName');
    var facilityName = (0, _get.default)(options, 'facilityName');
    var locationName = (0, _get.default)(options, 'locationName');
    var date = (0, _date.default)(new Date().toString(), {
      outFormat: 'MM/DD/YYYY h:mm:ss a'
    });
    if (exportType === 'csv') {
      (0, _exportPatientsCsv.default)({
        patients: patients,
        userName: userName,
        facilityName: facilityName,
        locationName: locationName,
        date: date
      });
    } else if (exportType === 'print') {
      var formattedPatients = formatPatients(patients);
      (0, _printPatients.default)({
        patients: formattedPatients,
        userName: userName,
        facilityName: facilityName,
        locationName: locationName,
        date: date
      });
    }
    actionContext.dispatch('CHECK_OUT_PATIENT_EXPORT_FINISH');
  } else {
    actionContext.dispatch('CHECK_OUT_SEARCH_SUCCESS', {
      results: response.data,
      page: params.page,
      pageSize: params.pageSize
    });
  }
}
function onFailed(actionContext, _ref3) {
  var data = _ref3.data,
    options = _ref3.options;
  var exportType = (0, _get.default)(options, 'exportType');
  if (exportType) {
    actionContext.dispatch('CHECK_OUT_PATIENT_EXPORT_FINISH');
  } else {
    actionContext.dispatch('CHECK_OUT_SEARCH_FAILED', {
      failed: data,
      succeeded: []
    });
  }
}
var _default = function _default(config) {
  return {
    serviceName: 'runSearchQuery',
    authenticate: true,
    config: {
      method: 'GET',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: (0, _get.default)(config, 'endpoints.emergencyCensus.checkIn.searchQuery')
      // baseUrl: 'http://localhost:3000'
    },
    beforeRequest: beforeRequest,
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(formatPatients, "formatPatients", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/run-search-query.js");
  reactHotLoader.register(beforeRequest, "beforeRequest", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/run-search-query.js");
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/run-search-query.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/run-search-query.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/run-search-query.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();