"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = scrollTopTop;
var _isNil = _interopRequireDefault(require("lodash/isNil"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function scrollTopTop(startElement) {
  var targetElement = startElement;
  while (!(0, _isNil.default)(targetElement)) {
    if (targetElement.scrollTop !== 0) {
      targetElement.scrollTop = 0;
    }
    targetElement = targetElement.parentNode;
  }
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(scrollTopTop, "scrollTopTop", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/scroll-to-top.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();