"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearStores = clearStores;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint-disable import/prefer-default-export */
function clearStores(actionContext) {
  actionContext.dispatch('CLEAR_STORES');
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(clearStores, "clearStores", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/home-actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();