"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.string.replace");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _isString = _interopRequireDefault(require("lodash/isString"));
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _startOfToday = _interopRequireDefault(require("date-fns/startOfToday"));
var _name = _interopRequireDefault(require("@audacious/components/lib/formatters/name"));
var _gender = _interopRequireDefault(require("@audacious/components/lib/formatters/gender"));
var _phone = _interopRequireDefault(require("@audacious/components/lib/formatters/phone"));
var _address = _interopRequireDefault(require("@audacious/components/lib/formatters/address"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function formatPatient(patient) {
  var dob = patient.dob,
    phone = patient.phone,
    countryCode = patient.countryCode,
    gender = patient.gender,
    checkinTime = patient.checkinTime,
    checkoutTime = patient.checkoutTime,
    checkoutType = patient.checkoutType,
    checkoutLocation = patient.checkoutLocation,
    dateOfDeath = patient.dateOfDeath,
    address1 = patient.address1,
    address2 = patient.address2,
    city = patient.city,
    state = patient.state,
    zip = patient.zip,
    ssn = patient.ssn;
  var cityState = null;
  if (city && state) {
    cityState = "".concat(city, ", ").concat(state);
  } else if (city) {
    cityState = city;
  } else if (state) {
    cityState = state;
  }
  var formattedCountryCode = !(0, _isNil.default)(countryCode) ? countryCode : '+01';
  if (!(0, _isNil.default)(zip) && zip.length > 0) {
    if (!(0, _isNil.default)(cityState)) {
      cityState = "".concat(cityState, " ").concat(zip);
    } else {
      cityState = zip;
    }
  }
  var maskedSSN = '';
  if (!(0, _isNil.default)(ssn)) {
    maskedSSN = ssn.replace(/\d{3}-\d{2}/, '***-**');
  }
  return {
    name: (0, _name.default)('fml', patient),
    nameLastFirst: (0, _name.default)('lfm', patient),
    fullAddress: (0, _address.default)({
      streets: [address1, address2],
      city: city,
      state: state,
      postalCode: zip
    }),
    address: {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zipCode: zip
    },
    cityState: !(0, _isNil.default)(cityState) ? cityState : '-',
    dobString: (0, _isString.default)(dob) ? (0, _moment.default)(dob).format('MM/DD/YYYY') : (0, _format.default)(dob, 'MM/dd/yyyy'),
    dobDate: (0, _isString.default)(dob) ? (0, _parse.default)(dob, 'yyyy-MM-dd', (0, _startOfToday.default)()) : dob,
    checkInDate: !(0, _isNil.default)(checkinTime) && checkinTime.length > 0 ? (0, _moment.default)(checkinTime).format('MM/DD/YYYY') : null,
    checkOutDate: !(0, _isNil.default)(checkoutTime) && checkoutTime.length > 0 ? (0, _moment.default)(checkoutTime).format('MM/DD/YYYY') : null,
    dateOfDeath: !(0, _isNil.default)(dateOfDeath) && dateOfDeath.length > 0 ? (0, _moment.default)(dateOfDeath).format('MM/DD/YYYY') : null,
    checkOutType: checkoutType,
    checkOutLocation: checkoutLocation,
    phone: (0, _phone.default)({
      number: phone,
      countryCode: formattedCountryCode
    }),
    gender: (0, _gender.default)(gender),
    ssn: maskedSSN
  };
}
var _default = formatPatient;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(formatPatient, "formatPatient", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/format-patient.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/format-patient.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();