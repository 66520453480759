"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function beforeRequest(actionContext) {
  actionContext.dispatch('CHECK_OUT_PATIENTS_START');
}
function onSuccess(actionContext, _ref) {
  var response = _ref.response;
  var notifications = actionContext.notifications,
    dispatch = actionContext.dispatch;
  var _response$data = response.data,
    successes = _response$data.successes,
    failures = _response$data.failures;
  dispatch('CHECK_OUT_PATIENTS_FINISH', [null, successes]);
  notifications.alert.clear();
  notifications.alert.set({
    heading: 'Check-Out Completed',
    message: "".concat(successes.length, " of ").concat(successes.length + failures.length, " successfully checked out."),
    color: 'success'
  });
  setTimeout(function () {
    notifications.alert.clear();
    dispatch('CHECK_OUT_CLEAR_NEW_PATIENTS', [null, successes]);
  }, 9000);
}
function onFailed(actionContext, _ref2) {
  var error = _ref2.error;
  var notifications = actionContext.notifications,
    dispatch = actionContext.dispatch;
  dispatch('CHECK_OUT_PATIENTS_FINISH', [error]);
  notifications.alert.set({
    heading: 'Check-Out Failed',
    message: 'Failed to successfully check out selected patients.',
    color: 'danger',
    actions: [{
      onPerform: function onPerform() {
        notifications.alert.clear();
      },
      label: 'Ok'
    }],
    blocking: true
  });
}
var _default = function _default(config) {
  return {
    serviceName: 'createCheckOuts',
    authenticate: true,
    config: {
      method: 'POST',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: (0, _get.default)(config, 'endpoints.emergencyCensus.checkOut.create')
    },
    beforeRequest: beforeRequest,
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(beforeRequest, "beforeRequest", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-out-service.js");
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-out-service.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-out-service.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-out-service.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();