"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _get = _interopRequireDefault(require("lodash/get"));
var _isNil = _interopRequireDefault(require("lodash/isNil"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var datesAreOnSameDay = function datesAreOnSameDay(first, second) {
  return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
};
function filterByDateRange(startDate, endDate, patients, path) {
  if ((0, _isNil.default)(startDate)) {
    return patients;
  }
  var filteredPatients = [];
  if ((0, _isNil.default)(endDate)) {
    (0, _forEach.default)(patients, function (patient) {
      var pathDate = new Date((0, _get.default)(patient, path));
      if (datesAreOnSameDay(startDate, pathDate)) {
        filteredPatients.push(patient);
      }
    });
  } else {
    (0, _forEach.default)(patients, function (patient) {
      var pathDate = new Date((0, _get.default)(patient, path));
      if ((datesAreOnSameDay(startDate, pathDate) || startDate <= pathDate) && (datesAreOnSameDay(endDate, pathDate) || endDate >= pathDate)) {
        filteredPatients.push(patient);
      }
    });
  }
  return filteredPatients;
}
var _default = filterByDateRange;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(datesAreOnSameDay, "datesAreOnSameDay", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/filter-date-range.js");
  reactHotLoader.register(filterByDateRange, "filterByDateRange", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/filter-date-range.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/filter-date-range.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();