"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INVALID_VALUE_MSG = exports.INVALID_CHARACTERS_MSG = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var INVALID_VALUE_MSG = exports.INVALID_VALUE_MSG = 'Enter a valid value';
var INVALID_CHARACTERS_MSG = exports.INVALID_CHARACTERS_MSG = 'Enter valid characters';
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(INVALID_VALUE_MSG, "INVALID_VALUE_MSG", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/error-messages.js");
  reactHotLoader.register(INVALID_CHARACTERS_MSG, "INVALID_CHARACTERS_MSG", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/constants/error-messages.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();