"use strict";

require("core-js/modules/es.array.slice");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkInServiceMock;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function checkInServiceMock(_ref) {
  var request = _ref.request,
    resolve = _ref.resolve;
  var patients = request.data;

  // make a random number of patients successes
  var numberSuccesses = Math.floor(Math.random() * patients.length + 1);
  var succeeded = patients.slice(0, numberSuccesses);
  var failed = [];
  if (numberSuccesses !== patients.length) {
    failed = patients.slice(numberSuccesses);
  }
  setTimeout(function () {
    resolve({
      succeeded: succeeded,
      failed: failed
    });
  }, 3000);
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(checkInServiceMock, "checkInServiceMock", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/mock/create-check-out-service.mock.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();