"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.regexp.constructor");
require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.regexp.to-string");
require("core-js/modules/es.string.match");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _words = _interopRequireDefault(require("lodash/words"));
var _escapeRegExp = _interopRequireDefault(require("lodash/escapeRegExp"));
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _filter = _interopRequireDefault(require("lodash/filter"));
var _isArray = _interopRequireDefault(require("lodash/isArray"));
var _join = _interopRequireDefault(require("lodash/join"));
var _map = _interopRequireDefault(require("lodash/map"));
var _get = _interopRequireDefault(require("lodash/get"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/**
 * Returns a string from the value in the item at valuePath.
 * If valuePath is an array it returns each value at each path
 * concatenated together into a string separted by spaces.
 */
function getValueFromPath(item, valuePath) {
  if ((0, _isArray.default)(valuePath)) {
    return (0, _join.default)((0, _map.default)(valuePath, function (path) {
      return (0, _get.default)(item, path, '');
    }), ' ');
  }
  return (0, _get.default)(item, valuePath, '');
}

/**
 * Builds the filter regex used to filter table rows from the filterText
 * string. Matching rows must have values strings that partially match each
 * word in filterText.
 */
function buildFilterRegex(filterText) {
  var filterWords = (0, _words.default)(filterText, /[^\s]+/g);

  // Construct a regex lookahead for each word, which is how to do AND
  // operators in regex
  var regexStr = '';
  (0, _forEach.default)(filterWords, function (word) {
    regexStr = "".concat(regexStr, "(?=.*").concat((0, _escapeRegExp.default)(word), ")");
  });
  return new RegExp("^".concat(regexStr, ".*$"), 'i');
}
function tableItemFilter(items, columns, filterText) {
  var filterRegex = null;
  if (filterText && filterText.length > 0) {
    filterRegex = buildFilterRegex(filterText);
  }
  if ((0, _isNil.default)(filterRegex)) {
    return items;
  }
  return (0, _filter.default)(items, function (item) {
    var rowFilterString = '';
    (0, _forEach.default)(columns, function (column) {
      var cellValuePath = (0, _get.default)(column, 'cellValuePath', null);
      var filterable = (0, _get.default)(column, 'filterable', true);
      var cellFilterPath = (0, _get.default)(column, 'cellFilterPath', null);
      if (!filterable) {
        return;
      }
      var cellFilterString = '';
      if (cellFilterPath) {
        cellFilterString = getValueFromPath(item, cellFilterPath);
      } else {
        cellFilterString = getValueFromPath(item, cellValuePath);
      }
      if (!(0, _isEmpty.default)(cellFilterString)) {
        rowFilterString = "".concat(rowFilterString, " ").concat(cellFilterString);
      }
    });
    return rowFilterString.match(filterRegex);
  });
}
var _default = tableItemFilter;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(getValueFromPath, "getValueFromPath", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/table-item-filter.js");
  reactHotLoader.register(buildFilterRegex, "buildFilterRegex", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/table-item-filter.js");
  reactHotLoader.register(tableItemFilter, "tableItemFilter", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/table-item-filter.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/table-item-filter.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();