"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _pulseFacilitySelection = require("@audacious/pulse-facility-selection");
var _checkInWorkflowStore = _interopRequireDefault(require("./check-in-workflow-store"));
var _checkOutWorkflowStore = _interopRequireDefault(require("./check-out-workflow-store"));
var _epraPreferencesStore = _interopRequireDefault(require("./epra-preferences-store"));
var _rosterStore = _interopRequireDefault(require("./roster-store"));
var _checkedOutRosterStore = _interopRequireDefault(require("./checked-out-roster-store"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = [_checkInWorkflowStore.default, _checkOutWorkflowStore.default, _epraPreferencesStore.default, _rosterStore.default, _checkedOutRosterStore.default].concat((0, _toConsumableArray2.default)(_pulseFacilitySelection.stores));
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/stores/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();