"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attemptCheckOut = attemptCheckOut;
exports.cancelDestination = cancelDestination;
exports.cancelReview = cancelReview;
exports.startCheckOut = startCheckOut;
exports.startConfirmation = startConfirmation;
exports.startDestination = startDestination;
exports.startReview = startReview;
var _isNil = _interopRequireDefault(require("lodash/isNil"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function startCheckOut(actionContext) {
  actionContext.dispatch('CHECK_OUT_START');
}
function startDestination(actionContext, selectedPatients) {
  actionContext.dispatch('CHECK_OUT_START_DESTINATION', selectedPatients);
}
function cancelDestination(actionContext) {
  actionContext.dispatch('CHECK_OUT_CANCEL_DESTINATION');
}
function startReview(actionContext, selectedPatients) {
  actionContext.dispatch('CHECK_OUT_START_REVIEW', selectedPatients);
}
function cancelReview(actionContext) {
  actionContext.dispatch('CHECK_OUT_CANCEL_REVIEW');
}
function startConfirmation(actionContext) {
  actionContext.dispatch('CHECK_OUT_START_CONFIRMATION');
}
function attemptCheckOut(actionContext, _ref) {
  var patients = _ref.patients,
    history = _ref.history;
  var createCheckOuts = actionContext.service.createCheckOuts,
    getStore = actionContext.getStore;
  var sessionStore = getStore('Session');
  var tenantId = sessionStore.getTenant().getId();
  var facility = sessionStore.getFacility();
  var facilityId = facility ? facility.getId() : '';
  createCheckOuts({
    options: {
      tenantId: tenantId,
      facilityId: facilityId
    },
    data: patients
  }, function (unused, serviceContext) {
    if ((0, _isNil.default)(serviceContext.error)) {
      history.push('/home/checked-out');
    }
  });
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(startCheckOut, "startCheckOut", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-out-actions.js");
  reactHotLoader.register(startDestination, "startDestination", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-out-actions.js");
  reactHotLoader.register(cancelDestination, "cancelDestination", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-out-actions.js");
  reactHotLoader.register(startReview, "startReview", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-out-actions.js");
  reactHotLoader.register(cancelReview, "cancelReview", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-out-actions.js");
  reactHotLoader.register(startConfirmation, "startConfirmation", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-out-actions.js");
  reactHotLoader.register(attemptCheckOut, "attemptCheckOut", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/check-out-actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();