"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var selectedPatientsPropType = _propTypes.default.objectOf(_propTypes.default.shape({
  patient: _propTypes.default.objectOf(_propTypes.default.string),
  formatted: _propTypes.default.shape({
    nameLastFirst: _propTypes.default.string,
    name: _propTypes.default.string,
    fullAddress: _propTypes.default.string,
    address: _propTypes.default.shape({
      address1: _propTypes.default.string,
      address2: _propTypes.default.string,
      city: _propTypes.default.string,
      state: _propTypes.default.string,
      zipCode: _propTypes.default.string
    }),
    dob: _propTypes.default.string,
    phone: _propTypes.default.string,
    gender: _propTypes.default.string
  }),
  destination: _propTypes.default.shape({
    type: _propTypes.default.string,
    deceased: _propTypes.default.objectOf({
      day: _propTypes.default.string,
      month: _propTypes.default.string,
      year: _propTypes.default.string
    }),
    transferredTo: _propTypes.default.string,
    checkOutLocation: _propTypes.default.string
  })
}));
var _default = selectedPatientsPropType;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(selectedPatientsPropType, "selectedPatientsPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/components/workflows/check-out/selected-patients-prop-type.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/components/workflows/check-out/selected-patients-prop-type.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();