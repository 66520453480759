"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkInsPropType = exports.checkInPatientPropType = exports.checkInData = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var checkInPatientPropType = exports.checkInPatientPropType = _propTypes.default.shape({
  firstName: _propTypes.default.string,
  middleName: _propTypes.default.string,
  lastName: _propTypes.default.string,
  dob: _propTypes.default.shape({
    day: _propTypes.default.number,
    month: _propTypes.default.number,
    year: _propTypes.default.number
  }),
  gender: _propTypes.default.string,
  address1: _propTypes.default.string,
  address2: _propTypes.default.string,
  city: _propTypes.default.string,
  state: _propTypes.default.string,
  zip: _propTypes.default.string,
  countryCode: _propTypes.default.string,
  phone: _propTypes.default.string,
  needsMedicalAttention: _propTypes.default.bool
});
var checkInData = exports.checkInData = _propTypes.default.shape({
  issues: _propTypes.default.arrayOf(_propTypes.default.shape({
    path: _propTypes.default.string,
    messages: _propTypes.default.arrayOf(_propTypes.default.shape({
      text: _propTypes.default.string
    }))
  })),
  patient: checkInPatientPropType,
  formatted: _propTypes.default.shape({
    name: _propTypes.default.string,
    address: _propTypes.default.shape({
      address1: _propTypes.default.string,
      address2: _propTypes.default.string,
      city: _propTypes.default.string,
      state: _propTypes.default.string,
      zipCode: _propTypes.default.string
    }),
    dob: _propTypes.default.string,
    phone: _propTypes.default.string,
    gender: _propTypes.default.string
  })
});
var checkInsPropType = exports.checkInsPropType = _propTypes.default.arrayOf(checkInData);
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(checkInPatientPropType, "checkInPatientPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/components/workflows/check-in/check-in-prop-type.js");
  reactHotLoader.register(checkInData, "checkInData", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/components/workflows/check-in/check-in-prop-type.js");
  reactHotLoader.register(checkInsPropType, "checkInsPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/components/workflows/check-in/check-in-prop-type.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();