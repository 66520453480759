"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _map = _interopRequireDefault(require("lodash/map"));
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _trim = _interopRequireDefault(require("lodash/trim"));
var _get = _interopRequireDefault(require("lodash/get"));
var _gender = _interopRequireDefault(require("@audacious/components/lib/formatters/gender"));
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _moment = _interopRequireDefault(require("moment"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function getFormattedDate(dateTime) {
  if (!(0, _isNil.default)(dateTime)) {
    var dateTimeObj = new Date((0, _trim.default)(dateTime));
    var dateObj = new Date((0, _trim.default)(dateTimeObj));
    return "".concat(dateObj.getMonth() + 1, "/").concat(dateObj.getDate(), "/").concat(dateObj.getFullYear());
  }
  return '';
}
function getFormattedDateOfDeath(dateOfDeath) {
  return (0, _moment.default)(dateOfDeath).format('MM/DD/YYYY');
}
function exportPatientsCsv(_ref) {
  var patients = _ref.patients,
    userName = _ref.userName,
    facilityName = _ref.facilityName,
    locationName = _ref.locationName,
    date = _ref.date,
    _ref$eventType = _ref.eventType,
    eventType = _ref$eventType === void 0 ? 'checked-in' : _ref$eventType;
  var formattedPatients = (0, _map.default)(patients, function (patientObj) {
    var patient = (0, _get.default)(patientObj, 'patient', {});
    return {
      firstName: (0, _trim.default)(patient.firstName),
      lastName: (0, _trim.default)(patient.lastName),
      middleName: (0, _trim.default)(patient.middleName),
      address: "".concat((0, _trim.default)(patient.address1), " ").concat((0, _trim.default)(patient.address2)),
      dob: (0, _trim.default)(patient.dob),
      gender: (0, _gender.default)(patient.gender),
      city: (0, _trim.default)(patient.city),
      state: (0, _trim.default)(patient.state),
      zip: (0, _trim.default)(patient.zip),
      phone: "".concat((0, _trim.default)(patient.countryCode), " ").concat((0, _trim.default)(patient.phone)),
      checkInDate: getFormattedDate(patient.checkinTime),
      checkOutDate: getFormattedDate(patient.checkoutTime),
      dateOfDeath: !(0, _isNil.default)(patient.dateOfDeath) ? getFormattedDateOfDeath(patient.dateOfDeath) : '',
      checkOutType: (0, _trim.default)(patient.checkoutType),
      checkOutLocation: (0, _trim.default)(patient.checkoutLocation)
    };
  });
  var dataString = '';
  dataString += "User Name,\"".concat(userName, "\"%0A");
  dataString += "Facility,\"".concat(facilityName, "\"%0A");
  dataString += "Location,\"".concat(locationName, "\"%0A");
  dataString += "Date,\"".concat(date, "\"%0A");
  dataString += '%0A';
  dataString += 'Last Name,First Name,Middle Name,DOB,Gender,Address,City,State,Zip Code,Phone,Check-in Date';
  if ((0, _isEqual.default)(eventType, 'checked-out')) {
    dataString += ',Check-out Date,Check-out Destination';
  }
  dataString += '%0A';
  (0, _forEach.default)(formattedPatients, function (patient) {
    dataString += "\"".concat(patient.lastName, "\",");
    dataString += "\"".concat(patient.firstName, "\",");
    dataString += "\"".concat(patient.middleName, "\",");
    dataString += "".concat(patient.dob, ",");
    dataString += "".concat(patient.gender, ",");
    dataString += "\"".concat(patient.address, "\",");
    dataString += "\"".concat(patient.city, "\",");
    dataString += "\"".concat(patient.state, "\",");
    dataString += "\"".concat(patient.zip, "\",");
    dataString += "".concat(patient.phone, ",");
    dataString += "".concat(patient.checkInDate);
    if ((0, _isEqual.default)(eventType, 'checked-out')) {
      dataString += ",".concat(patient.checkOutDate, ",");
      dataString += "".concat(patient.checkOutType);
      if (!(0, _isEmpty.default)(patient.dateOfDeath)) {
        dataString += " (".concat(patient.dateOfDeath, ")");
      }
      if (!(0, _isEmpty.default)(patient.checkOutLocation)) {
        dataString += " - ".concat(patient.checkOutLocation);
      }
    }
    dataString += '%0A';
  });
  var link = document.createElement('a');
  link.href = "data:text/csv;charset=UTF-8,".concat(dataString.replaceAll('#', '%23')); // Pound symbol breaks CSV, so encode it

  if ((0, _isEqual.default)(eventType, 'checked-out')) {
    link.download = 'checked-out-patients.csv';
  } else {
    link.download = 'checked-in-patients.csv';
  }
  link.click();
}
var _default = exportPatientsCsv;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(getFormattedDate, "getFormattedDate", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/export-patients-csv.js");
  reactHotLoader.register(getFormattedDateOfDeath, "getFormattedDateOfDeath", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/export-patients-csv.js");
  reactHotLoader.register(exportPatientsCsv, "exportPatientsCsv", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/export-patients-csv.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/export-patients-csv.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();