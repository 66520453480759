"use strict";

require("core-js/modules/es.number.constructor");
require("core-js/modules/es.number.is-nan");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dates = require("../constants/dates");
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function validateDob(value) {
  if (!value) {
    return null;
  }
  var dateValue = new Date(value);
  if (Number.isNaN(dateValue.getTime())) {
    return 'Enter a valid value';
  }
  if (dateValue > _dates.MAX_DATE) {
    return 'Future dates are invalid';
  }
  return null;
}
var _default = validateDob;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(validateDob, "validateDob", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/validate-dob.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/validate-dob.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();