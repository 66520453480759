"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = showNotification;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function showNotification(context, payload) {
  var _context$notification = context.notifications.alert,
    set = _context$notification.set,
    clear = _context$notification.clear;
  set(payload);
  setTimeout(clear, 3000);
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(showNotification, "showNotification", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/show-notification.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();