"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.string.starts-with");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.definitionOrder = exports.columnDefinitions = void 0;
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _toUpper = _interopRequireDefault(require("lodash/toUpper"));
var _reduce = _interopRequireDefault(require("lodash/reduce"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _usaStates = _interopRequireDefault(require("usa-states"));
var _gender = _interopRequireDefault(require("@audacious/components/lib/formatters/gender"));
var _phone = _interopRequireDefault(require("@audacious/components/lib/formatters/phone"));
var _validators = require("@audacious/components/lib/validators");
var _regExPatterns = require("../constants/reg-ex-patterns");
var _dates = require("../constants/dates");
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var provinces = new _usaStates.default.UsaStates({
  includeTerritories: true
});
var provinceLookup = (0, _reduce.default)(provinces.states, function (acc, state) {
  acc[state.abbreviation] = state;
  return acc;
}, {});
function stateValidator(value) {
  if ((0, _isNil.default)(value)) {
    return null;
  }
  var province = provinceLookup[value];
  if (!(0, _isNil.default)(province)) {
    return null;
  }
  return 'Invalid State';
}
function genderValidator(value) {
  if ((0, _isNil.default)(value) || (0, _isEmpty.default)(value)) {
    return null;
  }
  var results = (0, _gender.default)(value);
  if ((0, _isEmpty.default)(results)) {
    return 'Invalid Gender Value';
  }
  return null;
}
function pushIfNotNil(array, result) {
  if (!(0, _isNil.default)(result)) {
    array.push(result);
    return false;
  }
  return true;
}
function parseString(value, capitalize) {
  if ((0, _isNil.default)(value) || value.length <= 0) {
    return null;
  }
  if (capitalize) {
    return (0, _toUpper.default)(value);
  }
  return value;
}
function parseDob(value) {
  if ((0, _isNil.default)(value) || value.length <= 0) {
    return null;
  }
  return new Date(value);
}
var definitionOrder = exports.definitionOrder = ['first name', 'middle name', 'last name', 'dob', 'gender', 'address line 1', 'address line 2', 'city', 'state', 'zip code', 'country code', 'phone', 'social security number'];
var columnDefinitions = exports.columnDefinitions = {
  'first name': {
    id: 'firstName',
    path: 'firstName',
    required: true,
    label: 'First Name',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.requiredValidator)(value));
      pushIfNotNil(messages, (0, _validators.minLengthValidator)(value, 2));
      pushIfNotNil(messages, (0, _validators.maxLengthValidator)(value, 50));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.namePattern));
      return {
        value: value,
        messages: messages
      };
    }
  },
  'middle name': {
    id: 'middleName',
    path: 'middleName',
    required: false,
    label: 'Middle Name',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.maxLengthValidator)(value, 50));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.namePattern));
      return {
        value: value,
        messages: messages
      };
    }
  },
  'last name': {
    id: 'lastName',
    path: 'lastName',
    required: true,
    label: 'Last Name',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.requiredValidator)(value));
      pushIfNotNil(messages, (0, _validators.minLengthValidator)(value, 2));
      pushIfNotNil(messages, (0, _validators.maxLengthValidator)(value, 50));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.namePattern));
      return {
        value: value,
        messages: messages
      };
    }
  },
  dob: {
    id: 'dob',
    path: 'dob',
    required: true,
    label: 'DOB',
    process: function process(rawValue) {
      var messages = [];
      var value = parseDob(rawValue);
      pushIfNotNil(messages, (0, _validators.requiredValidator)(value));
      pushIfNotNil(messages, (0, _validators.dateValidator)(value));
      pushIfNotNil(messages, (0, _validators.minDateValidator)(value, _dates.MIN_DATE));
      pushIfNotNil(messages, (0, _validators.maxDateValidator)(value, _dates.MAX_DATE));
      if (messages.length > 0) {
        value = '';
      }
      return {
        value: value,
        messages: messages
      };
    }
  },
  gender: {
    id: 'gender',
    path: 'gender',
    required: true,
    label: 'Gender',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.requiredValidator)(value));
      pushIfNotNil(messages, genderValidator(value));
      return {
        value: value,
        messages: messages
      };
    }
  },
  'address line 1': {
    id: 'address1',
    path: 'address1',
    required: false,
    label: 'Address Line 1',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.minLengthValidator)(value, 2));
      pushIfNotNil(messages, (0, _validators.maxLengthValidator)(value, 255));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.basicPattern));
      return {
        value: value,
        messages: messages
      };
    }
  },
  'address line 2': {
    id: 'addressLine2',
    path: 'address2',
    required: false,
    label: 'Address Line 2',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.minLengthValidator)(value, 2));
      pushIfNotNil(messages, (0, _validators.maxLengthValidator)(value, 255));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.basicPattern));
      return {
        value: value,
        messages: messages
      };
    }
  },
  city: {
    id: 'city',
    path: 'city',
    required: false,
    label: 'City',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.minLengthValidator)(value, 2));
      pushIfNotNil(messages, (0, _validators.maxLengthValidator)(value, 35));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.basicPattern));
      return {
        value: value,
        messages: messages
      };
    }
  },
  state: {
    id: 'state',
    path: 'state',
    required: false,
    label: 'State',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, stateValidator(value));
      return {
        value: value,
        messages: messages
      };
    }
  },
  'zip code': {
    id: 'zip',
    path: 'zip',
    required: true,
    label: 'Zip Code',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.requiredValidator)(value));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.zipCodePattern, 'Invalid format'));
      if (messages.length > 0) {
        value = '';
      }
      return {
        value: value,
        messages: messages
      };
    }
  },
  'country code': {
    id: 'countryCode',
    path: 'countryCode',
    required: true,
    label: 'Country Code',
    process: function process(rawValue) {
      var messages = [];
      var value = '+1';
      if (!(0, _isNil.default)(rawValue)) {
        value = parseString(rawValue);
        if (!value.startsWith('+')) {
          value = "+".concat(value);
        }
        pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.countryCodePattern, 'The country code is invalid. Please enter a valid country code.'));
        if (messages.length > 0) {
          value = '';
        }
      }
      return {
        value: value,
        messages: messages
      };
    }
  },
  phone: {
    id: 'phone',
    path: 'phone',
    required: true,
    label: 'Phone',
    process: function process(rawValue) {
      var messages = [];
      var value = (0, _phone.default)(rawValue);
      pushIfNotNil(messages, (0, _validators.requiredValidator)(value));
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.phonePattern, 'The phone number is invalid. Please enter a ten-digit phone number.'));
      if (messages.length > 0) {
        value = '';
      }
      return {
        value: value,
        messages: messages
      };
    }
  },
  'social security number': {
    id: 'ssn',
    path: 'ssn',
    required: false,
    label: 'Social Security Number',
    process: function process(rawValue) {
      var messages = [];
      var value = parseString(rawValue);
      pushIfNotNil(messages, (0, _validators.patternValidator)(value, _regExPatterns.socialPattern, 'The social security number is invalid. Please enter a 4 or 9 digit SSN.'));
      return {
        value: value,
        messages: messages
      };
    }
  }
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(provinces, "provinces", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(provinceLookup, "provinceLookup", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(stateValidator, "stateValidator", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(genderValidator, "genderValidator", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(pushIfNotNil, "pushIfNotNil", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(parseString, "parseString", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(parseDob, "parseDob", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(definitionOrder, "definitionOrder", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
  reactHotLoader.register(columnDefinitions, "columnDefinitions", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/csv/column-definitions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();