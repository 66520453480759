"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = {
  emergencyCensus: {
    checkIn: {
      create: '/pulse/v1/census/facilities/{facilityId}/checkin',
      searchQuery: '/pulse/v1/census/facilities/{facilityId}/patients',
      edit: '/pulse/v1/census/facilities/{facilityId}/checkin'
    },
    checkOut: {
      create: '/pulse/v1/census/facilities/{facilityId}/checkout',
      searchQuery: '/pulse/v1/census/facilities/{facilityId}/checkedOutPatients'
    }
  },
  epraPreferences: {
    retrieveEpraPreferences: '/common/v1/tenants/{tenantId}/settings/epraPreferences'
  }
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/configs/endpoints.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();