"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.string.search");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = runSearchQuery;
var _get = _interopRequireDefault(require("lodash/get"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function runSearchQuery(context, input) {
  var getStore = context.getStore,
    service = context.service;
  var _input$newPagination = input.newPagination,
    newPagination = _input$newPagination === void 0 ? {} : _input$newPagination,
    _input$search = input.search,
    search = _input$search === void 0 ? '' : _input$search,
    _input$options = input.options,
    options = _input$options === void 0 ? {} : _input$options;
  var exportType = (0, _get.default)(options, 'exportType');
  var checkOutWorkflowStore = getStore('CheckOutWorkflowStore');
  var pagination = checkOutWorkflowStore.getPagination();
  var page = newPagination.page || pagination.page;
  var pageSize = newPagination.pageSize || pagination.pageSize;
  if (exportType) {
    page = 1;
    pageSize = 100000;
  }
  // const orderBy = checkOutWorkflowStore.getOrderBy();

  service.runSearchQuery({
    params: {
      page: page,
      pageSize: pageSize,
      search: search
    },
    options: options
  });
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(runSearchQuery, "runSearchQuery", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/actions/run-search-query.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();