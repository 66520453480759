"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.function.name");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _map = _interopRequireDefault(require("lodash/map"));
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function printPatients(_ref) {
  var patients = _ref.patients,
    userName = _ref.userName,
    facilityName = _ref.facilityName,
    locationName = _ref.locationName,
    date = _ref.date,
    _ref$eventType = _ref.eventType,
    eventType = _ref$eventType === void 0 ? 'checked-in' : _ref$eventType;
  var formattedPatients = (0, _map.default)(patients, 'formatted');
  var tableHeader = '';
  tableHeader += '<tr>';
  tableHeader += "<th>Name</th>";
  tableHeader += "<th>DOB</th>";
  tableHeader += "<th>Gender</th>";
  tableHeader += "<th>Address</th>";
  tableHeader += "<th>Phone</th>";
  tableHeader += "<th>Check-In Date</th>";
  if ((0, _isEqual.default)(eventType, 'checked-out')) {
    tableHeader += "<th>Check-out Date</th>";
    tableHeader += "<th>Check-out Destination</th>";
  }
  tableHeader += '</tr>';
  var tableRows = '';
  (0, _forEach.default)(formattedPatients, function (patient) {
    var formattedDateOfDeath = patient.dateOfDeath ? "(".concat(patient.dateOfDeath, ")") : '';
    var checkoutLocation = !(0, _isEmpty.default)(patient.checkOutLocation) ? patient.checkOutLocation : '';
    tableRows += '<tr>';
    tableRows += "<td>".concat(patient.name, "</td>");
    tableRows += "<td>".concat(patient.dob, "</td>");
    tableRows += "<td>".concat(patient.gender, "</td>");
    tableRows += "<td>".concat(patient.fullAddress, "</td>");
    tableRows += "<td>".concat(patient.phone, "</td>");
    tableRows += "<td>".concat(patient.checkInDate, "</td>");
    if ((0, _isEqual.default)(eventType, 'checked-out')) {
      tableRows += "<td>".concat(patient.checkOutDate, "</td>");
      tableRows += "<td>\n                            <div>".concat(patient.checkOutType, " ").concat(formattedDateOfDeath, "</div>\n                            <div>").concat(checkoutLocation, "</div>\n                        </td>");
    }
    tableRows += '<tr>\n';
  });
  var title = 'Checked In Patients';
  if ((0, _isEqual.default)(eventType, 'checked-out')) {
    title = 'Checked Out Patients';
  }
  var printWindow = window.open('', 'PRINT', 'height=600,width=800');
  printWindow.document.write("\n<html>\n<head>\n<title>\n".concat(title, "\n</title>\n<style>\n\nbody {\n    font-family: sans-serif;\n}\n\n.info .item {\n    display: flex;\n}\n\n.info .item .title {\n    width: 150px;\n    font-weight: bold;\n}\n\ntable {\n    width: 100%;\n}\n\ntd {    \n    padding: 5px;\n    vertical-align: top;\n    color: rgb(77, 77, 77);\n    font-size: 13px;\n    font-weight: normal;\n    letter-spacing: 0;\n    width: 100px;\n    line-height: 18px;how to make table cloumns uneven \n}\n\nth {    \n    vertical-align: bottom;\n    padding: 10px 5px 10px 5px;\n    text-align: left;\n    color: rgb(0, 0, 0);\n    font-size: 13px;\n    font-weight: 600;\n    height: 18px;\n    letter-spacing: 0;\n    width: 100px;\n}\n\n</style>\n</head>\n<body>\n\n<div class=\"info\">\n    <div class=\"item\">\n        <div class=\"title\">\n            Facility:\n        </div>\n        <div class=\"value\">\n            ").concat(facilityName, "\n        </div>\n    </div>\n    <div class=\"item\">\n        <div class=\"title\">\n            Location:\n        </div>\n        <div class=\"value\">\n            ").concat(locationName, "\n        </div>\n    </div>\n</div>\n\n<table>\n    ").concat(tableHeader, "\n    ").concat(tableRows, "\n</table>\n\n<div class=\"info\">\n    <div class=\"item\">\n        <div class=\"title\">\n            User Name:\n        </div>\n        <div class=\"value\">\n            ").concat(userName, "\n        </div>\n    </div>\n    <div class=\"item\">\n        <div class=\"title\">\n            Date:\n        </div>\n        <div class=\"value\">\n            ").concat(date, "\n        </div>\n    </div>\n</div>\n\n</body>\n</html>\n    "));
  printWindow.document.close();
  printWindow.focus();
  setTimeout(function () {
    printWindow.print();
    printWindow.close();
  }, 10);
}
var _default = printPatients;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(printPatients, "printPatients", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/print-patients.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/util/print-patients.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();