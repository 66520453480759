"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function beforeRequest(actionContext) {
  actionContext.dispatch('CHECK_IN_CREATE_START');
}
function onSuccess(actionContext, _ref) {
  var response = _ref.response;
  var _response$data = response.data,
    succeeded = _response$data.successes,
    failed = _response$data.failures;
  actionContext.dispatch('MARK_NEW_CHECKED_IN_PATIENT', {
    succeeded: succeeded,
    failed: failed
  });
  actionContext.dispatch('CHECK_IN_CREATE_FINISH');
}
function onFailed(actionContext, _ref2) {
  var data = _ref2.data;
  actionContext.dispatch('CHECK_IN_CREATE_FAILED', {
    failed: data.patients,
    succeeded: []
  });
}
var _default = function _default(config) {
  return {
    serviceName: 'createCheckIns',
    authenticate: true,
    config: {
      method: 'POST',
      authenticate: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: (0, _get.default)(config, 'endpoints.emergencyCensus.checkIn.create')
    },
    beforeRequest: beforeRequest,
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(beforeRequest, "beforeRequest", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-in-service.js");
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-in-service.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-in-service.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/services/service-configs/create-check-in-service.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();