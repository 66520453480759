"use strict";

require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.string.replace");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = socialMask;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var ssn4Mask = [/\d/, /\d/, /\d/, /\d/];
var ssn9Mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
function socialMask(value) {
  if (value.replace('-', '').length <= 4) {
    return ssn4Mask;
  }
  return ssn9Mask;
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(ssn4Mask, "ssn4Mask", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/social-mask.js");
  reactHotLoader.register(ssn9Mask, "ssn9Mask", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/social-mask.js");
  reactHotLoader.register(socialMask, "socialMask", "/builds/audacious-inquiry/portfolio/pulse/pulse-emergency-census-application/clone/src/common/social-mask.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();